@import '@assets/styles/extends.scss';
@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.vehicle-item {
  display: flex;
  margin-bottom: 10px;

  .vehicle-ctrl {
    align-items: center;
    display: flex;
    margin-left: 12px;
    width: 104px;

    .btn {
      font-weight: 600;
      height: 45px;
      line-height: 1.36;
      padding: 2px 12px;
      width: 100%;

      span {
        text-align: center;
        white-space: inherit;
      }

      &-available {
        @include apply-button-color($color-green-grass);
      }

      &-unavailable {
        @include apply-button-color($color-red-middle-1);
      }
    }
  }

  .vehicle-details {
    flex: 1;
    padding: 0 12px 0 20px;

    .details-header {
      align-items: center;
      display: flex;
      margin: 14px 0 10px 4px;

      .agency-logo {
        height: 44px;
        width: 44px;
        object-fit: contain;
      }

      .details-title {
        align-items: center;
        cursor: default;
        display: flex;
        font-size: 18px;
        margin-left: 20px;
        max-width: 600px;

        .armoured {
          align-items: center;
          color: $color-yellow-1;
          display: flex;
          font-size: 12px;
          margin-left: 18px;

          &-icon {
            align-items: stretch;
            display: flex;
            justify-content: stretch;
            margin-left: 18px;
            max-height: 15px;
            max-width: 20px;
            position: relative;
            top: -1px;

            svg {
              fill: $color-yellow-1 !important;
              height: 100%;
              margin-right: 5px;
              width: 100%;
            }
          }
        }
      }

      .shared-label {
        background-color: $color-blue-primary;
        border-radius: $border-radius-md;
        margin-left: 20px;
        padding: 0 10px;
      }

      .btn-edit {
        align-self: baseline;
        margin-left: auto;
        min-height: 20px;
        opacity: 0.5;
        top: -5px;

        svg {
          fill: $color-white;
        }
      }
    }

    .details-content {
      display: flex;
      justify-content: space-between;
      padding-left: 10px;

      .item {
        align-items: center;
        display: flex;

        &:nth-child(1),
        &:nth-child(4) {
          .icon {
            svg {
              transform: scale(1.3);
            }
          }
        }

        .icon {
          margin-right: 10px;
          svg {
            fill: $color-white;
            height: 20px;
            width: 20px;
            opacity: 0.6;
          }
        }

        .label {
          color: $color-grey-light-6;
          font-size: 12px;
        }

        .value {
          color: $color-white;
          font-size: 14px;
        }
      }
    }
  }

  .vehicle-image {
    background-color: $color-grey-dark-3;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px 0 0 8px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 210px;

    svg {
      fill: $color-white;
      height: 40px;
      width: 65px;
    }

    .vehicle-status {
      background-color: $color-red-dark-1;
      font-size: 12px;
      padding: 4px 0;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .vehicle-info {
    background-color: $color-blue-dark-3;
    border-radius: 8px;
    color: $color-white;
    display: flex;
    max-width: 945px;
    min-height: 114px;
    width: 100%;
  }
}
