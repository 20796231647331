@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

.cost-recovery-report-page {
  max-width: $width-xl !important;

  .container {
    background-color: $color-white;
    border-bottom-left-radius: $border-radius-md;
    border-bottom-right-radius: $border-radius-md;
    border-top-right-radius: $border-radius-md;
    color: $color-grey-dark-2;
    display: flex;
    flex-direction: column;
    margin: 0 0 36px;
    padding: 30px 0 0;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .btn-export {
    @include apply-button-color($color-green-middle-1);
  }

  .tabs {
    .hbh-tab-select {
      background-color: $color-white;
      color: $color-grey-dark-3;
    }
  }

  .report-details {
    @extend .container;

    &-form {
      @extend .flex;
      margin: 0 0 30px;
      padding: 0 25px;
      width: 100%;

      .export-fields {
        @extend .flex;
        width: 35%;

        .field {
          width: 32%;
        }
      }

      .filters {
        @extend .flex;
        width: 60%;

        .search {
          width: 35%;
        }

        .carpooled,
        .rideshared {
          width: 15%;
        }

        .month {
          width: 18%;
        }

        .year {
          width: 12%;
        }
      }
    }

    &-table {
      margin-bottom: 25px;

      .hbh-table-light {
        thead {
          tr {
            th {
              font-weight: 600;
            }
          }
        }

        tbody {
          tr {
            td {
              &:nth-child(16) {
                font-weight: bold;
              }

              &:nth-child(17) {
                .col-status {
                  align-items: center;
                  display: flex;
                  justify-content: space-around;
                  font-size: 10px;
                  padding: 0;
                  text-align: center;

                  &-carpooled {
                    color: $color-blue-secondary;
                    width: 28px;

                    svg {
                      fill: $color-blue-secondary;
                    }
                  }

                  &-fuel-type {
                    width: 24px;

                    svg {
                      height: 20px;
                      width: 20px;
                    }
                  }

                  &-shared {
                    color: $color-green-grass;
                    width: 28px;

                    svg {
                      fill: $color-green-grass;
                    }
                  }

                  &-summary {
                    color: $color-yellow-dark-1;
                    width: 20px;

                    svg {
                      fill: $color-yellow-dark-1;
                    }
                  }
                }
              }

              &:last-child {
                font-weight: bold;

                .col-actions {
                  align-items: center;
                  display: flex;
                  justify-content: space-around;

                  .btn-booking-details {
                    border: 1px solid #98a6b5;
                    height: 20px;
                    min-height: auto;
                    padding: 0;
                    position: relative;
                    width: 20px;

                    &:after {
                      align-items: center;
                      color: #98a6b5;
                      content: '\003E';
                      display: flex;
                      justify-content: center;
                      position: absolute;
                      top: 1px;
                      width: 20px;
                    }
                  }

                  .btn-edit {
                    svg {
                      fill: $color-grey-light-3;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .summary {
    @extend .flex;
    flex: 1;

    &-list {
      @extend .container;
      flex: 1;

      &-form {
        @extend .flex;
        margin: 0 0 30px;
        padding: 0 25px;
        width: 100%;

        .export-fields {
          @extend .flex;
          width: 45%;

          .field {
            width: 40%;
          }
        }

        .filters {
          @extend .flex;
          width: 30%;

          .month {
            width: 55%;
          }

          .year {
            width: 40%;
          }
        }
      }

      &-table {
        margin-bottom: 25px;

        .hbh-table-light {
          thead {
            tr {
              th {
                font-weight: 600;

                &:first-child {
                  width: 150px;
                }

                &:last-child {
                  width: 110px;
                }
              }
            }
          }

          tbody {
            tr {
              td {
                &:nth-child(10) {
                  font-weight: bold;
                }

                &:last-child {
                  .col-actions {
                    .btn-invoice,
                    .btn-download {
                      font-size: 12px;
                      height: 28px;
                      min-height: auto;
                      min-width: 100px;
                      padding: 6px;
                    }

                    .btn-download:hover {
                      color: $color-white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &-monthly {
      margin-left: 4px;
      max-height: 268px;
      width: 280px;

      .monthly-summary-box {
        background-color: $color-white;
        border-radius: $border-radius-md;
        color: $color-grey-middle-1;
        overflow: hidden;
        width: 100%;

        div.header {
          align-items: center;
          display: flex;
          font-size: 20px;
          padding: 20px 20px 11px 22px;

          svg {
            fill: $color-grey-middle-1;
            margin-right: 15px;
          }
        }

        .row {
          @extend .flex;
          align-items: center;
          border-bottom: 1px solid $color-grey-light-1;
          padding: 5px 20px 5px 22px;
          margin: 0;
          min-height: 39px;
        }

        .footer {
          @extend .flex;
          align-items: center;
          background-color: $color-blue-primary;
          color: $color-white;
          height: 54px;
          padding: 0 20px;
        }

        .label {
          font-size: 12px;
        }

        .value {
          font-size: 16px;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-start;
    padding: 0 25px 20px;
  }
}
