.container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .chatbot {
    opacity: 0;
    border: none;
  }
  &.opened .chatbot {
    animation: 0.5s linear 1s forwards chatbot-open;
  }
  &.closed.animate .chatbot {
    animation: 0.5s linear forwards chatbot-close;
  }
  .chatbot-wrap {
    background: #0b3d5e;
    border-radius: 15px;
    overflow: hidden;
    height: 0px;
    width: 0px;
    transform: translate(-26px, 26px);
  }
  &.opened .chatbot-wrap {
    animation: 0.5s cubic-bezier(0, 0, 0.14, 1.02) 0.5s forwards chatbot-wrap-open;
  }
  &.closed.animate .chatbot-wrap {
    animation: 0.5s cubic-bezier(0, 0, 0.14, 1.02) forwards chatbot-wrap-close;
  }
  .button {
    height: 54px;
    width: 254px;
    background: #0b3d5e;
    border: none;
    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    white-space: nowrap;
    padding: 0 0 0 1em;
    cursor: pointer;
  }
  .button-wrap {
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    width: 54px;
    border-radius: 54px;
  }
  &.opened .button-wrap {
    animation: 0.5s cubic-bezier(0, 0, 0.14, 1.02) forwards button-close;
  }
  &.closed .button-wrap {
    animation: 0.5s cubic-bezier(0, 0, 0.14, 1.02) 0.5s forwards button-open;
  }
  .icon-wrap {
    height: 54px;
    width: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    svg {
      height: 40px;
      width: 40px;
    }
  }
  .icon-slider {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 0 7px;
    transition: left 0.5s cubic-bezier(0, 0, 0.14, 1.02);
  }
  &.closed .icon-slider {
    left: -54px;
  }
}

@keyframes button-close {
  0% {
    width: 254px;
    border-radius: 15px;
  }
  80% {
    border-radius: 25px;
  }
  100% {
    width: 54px;
    border-radius: 54px;
  }
}
@keyframes button-open {
  0% {
    width: 54px;
    border-radius: 54px;
  }

  100% {
    width: 254px;
    border-radius: 15px;
  }
}

@keyframes chatbot-wrap-close {
  0% {
    height: min(570px, calc(100vh - 200px));
    width: 450px;
    transform: translate(0px, -30px);
  }
  100% {
    height: 0px;
    width: 0px;
    transform: translate(-27px, 27px);
  }
}
@keyframes chatbot-wrap-open {
  0% {
    height: 0px;
    width: 0px;
    transform: translate(-27px, 27px);
  }
  100% {
    height: min(570px, calc(100vh - 200px));
    width: 450px;
    transform: translate(0px, -30px);
  }
}
@keyframes chatbot-close {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes chatbot-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
