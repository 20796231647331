@import '@assets/styles/mixins.scss';
@import '@assets/styles/variables.scss';

@mixin divider {
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% + 20px * 2);
    height: 1px;
    background-color: $color-grey-dark-5;
    margin-left: -20px;
  }
}

.driver-info-popup {
  width: 370px;
  height: 650px;
  font: inherit;
  color: $color-white;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  animation: fade-in 0.2s;
  overflow-y: auto;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .header {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .icon-container {
      border-radius: 4px;
      width: 34px;
      height: 34px;
      margin-right: 12px;
      @include center-items;

      &-issue {
        background-color: #e2800d;
      }

      &-critical {
        background-color: #e3401d;
      }
    }

    &-issue {
      background-color: $color-yellow-middle-1;
    }

    &-critical {
      background-color: $color-red-light-3;
    }

    h2 {
      font-size: 20px;
      font-weight: 300;
    }

    button {
      padding: 0;
      background: none;
      border: 0;
      margin-left: auto;

      .close-icon {
        width: 12px !important;
        height: 12px !important;
        fill: $color-white;
      }
    }
  }

  .content {
    padding: 20px;
    min-height: 400px;
    background-color: $color-grey-dark-4;
    border: 1px solid $color-grey-dark-4;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    > *:not(:last-child) {
      padding-bottom: 14px;
    }

    h3,
    h4,
    h5 {
      margin: 0;
      font-weight: 400;
    }

    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 14px;
    }

    h5 {
      font-size: 11px;
      color: #bfbfbf;
    }

    .main-info {
      @include divider;

      .date {
        margin-bottom: 14px;
        color: white;
      }

      .driver {
        margin-bottom: 10px;
        color: white;
      }

      .vehicle {
        display: flex;
        margin-top: 8px;
        margin-bottom: 20px;

        h4 {
          color: white;
        }

        .image {
          width: 75px;
          height: 37px;
          margin-right: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .critical-issue {
    margin-right: 5px;
  }

  .issues {
    .issue {
      list-style: none;
      padding: 10px 20px;
      @include divider;

      &::before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        vertical-align: middle;
        width: 5px;
        height: 5px;
        background-color: $color-white;
        border-radius: 50%;
      }

      img {
        margin-top: 5px;
        max-width: 150px;
        height: 100px;
        border-radius: 10px;
        display: block;
      }

      q {
        display: block;
        font-size: 12px;
        font-style: italic;
        margin-left: 30px;
        quotes: '“' '”';
      }
    }
  }
}
