@import './extends.scss';
@import './mixins.scss';
@import './variables.scss';

body {
  font-family: $font-default !important;
  min-width: 380px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold,
b {
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

.page-container {
  align-self: center;
  box-sizing: border-box;
  font-family: $font-default;
  max-width: $width-lg;
  width: 100%;
  margin: 0 auto 20px;
  color: $color-white;
}

.btn {
  font-weight: 500;

  &-accepted {
    @include apply-button-color($color-green-middle-1);
  }

  &-cancelled {
    @include apply-button-color($color-yellow-2);
  }

  &-cancelled-fp {
    background-color: $color-grey-dark-4;
    border-color: $color-red-dark-1;

    &:hover {
      background-color: lighten($color-grey-dark-4, 10%);
      border-color: lighten($color-red-dark-1, 10%);
    }
  }

  &-disabled {
    pointer-events: none;
  }

  &-finished {
    @include apply-button-color($color-green-light-2);
  }

  &-not-processed {
    @include apply-button-color($color-red-dark-1);
  }

  &-ongoing {
    @include apply-button-color($color-green-light-1);
  }

  &-rejected {
    @include apply-button-color($color-grey-dark-6);
  }
}

form {
  .input-text-label {
    color: $color-white;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 5px;
  }
}

.field {
  position: relative;

  .hbh-date-label,
  .hbh-select-label,
  .hbh-text-label {
    color: $color-grey-middle-1;
    font-size: 12px;
    line-height: 1.4;
    margin: 0 0 8px;
  }

  .hbh-date,
  .hbh-text-field {
    border: 1px solid $color-grey-light-1;
    font-size: 12px;
    height: 30px;
  }

  .hbh-text-area-field {
    border: 1px solid $color-grey-light-1;
    font-size: 12px;
  }

  .hbh-select {
    .rs__control {
      border: 1px solid $color-grey-light-1;
      font-size: 12px;
      height: 30px;
    }

    .rs__indicator {
      svg {
        fill: $color-grey-light-1;
      }
    }
  }

  .hbh-text-field + small {
    color: $color-red-light-1;
    display: flex;
    font-size: 9px;
    margin: 0;
    position: absolute;
  }
}

.select {
  &.dark {
    .rs__control {
      align-items: center;
      border-radius: $border-radius-small;
      display: flex;
      background-color: $color-grey-dark-4;
      border: 1px solid $color-grey-dark-5;
      font-size: 14px;
      height: 36px;
      min-width: 100px;
      padding: 1px 0 1px 8px;
      position: relative;
    }

    .rs__indicator {
      svg {
        fill: $color-white;
      }
    }

    .rs__single-value {
      color: $color-white;
    }
  }
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.empty-list {
  @extend .flex-col-center;
  color: $color-white;
  font-size: 14px;
  opacity: 0.75;
  padding: 20px 0;
  height: 100%;
}

.modal-light-form {
  .field {
    flex: auto;

    .hbh-date-label,
    .hbh-select-label,
    .hbh-text-label {
      color: $color-grey-middle-1;
      font-size: 12px;
      line-height: 1.4;
      margin: 0 0 8px;
    }

    .hbh-date,
    .hbh-text-field {
      border: 1px solid $color-grey-light-5;
      border-radius: $border-radius-small;
      font-size: 14px;
      height: 38px;
    }

    .hbh-select {
      .rs__control {
        border: 1px solid $color-grey-light-5;
        border-radius: $border-radius-small;
        font-size: 14px;
        height: 38px;
      }

      .rs__indicator svg {
        fill: $color-grey-light-5;
      }

      .rs__value-container--is-multi {
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}

.page-no-access {
  align-items: center;
  background: url('../img/default/background.jpg') no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .container {
    @extend .row;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    color: $color-white;
    justify-content: space-between;
    max-width: 800px;
    padding: 20px;

    &:has(.links) {
      flex-direction: column;
    }

    svg {
      fill: $color-white;
    }

    .message {
      border-left: 1px solid $color-white;
      color: inherit;
      font-size: 30px;
      font-weight: 300;
      line-height: 1.2;
      margin-left: 2%;
      padding-left: 2%;
      max-width: 88%;
    }

    .links {
      display: flex;
      gap: 3rem;
      margin-top: 45px;

      .link {
        border: 2px solid rgba(255, 255, 0, 1) !important;
        border-radius: 50px;
        color: rgba(255, 255, 0, 1) !important;
        display: inline-block;
        font-size: 14px;
        min-height: 44px;
        padding: 8px 30px 9px;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

.field-calendar {
  .react-datepicker {
    font-family: $font-default !important;
    width: 330px;

    &__current-month {
      display: none !important;
    }

    &__navigation {
      &-icon {
        &::before {
          border-color: $color-grey-dark-1;
          height: 12px;
          top: 14px;
          width: 12px;
        }
      }
    }

    &__header {
      background-color: inherit;
      border: none;
      padding: 0 0 4px;

      &__dropdown {
        min-height: 24px;
        margin: 10px 0 4px;

        select {
          height: 24px;
          border: 1px solid $color-grey-light-1;
          border-radius: $border-radius-small;
        }
      }
    }

    &__current-month {
      display: inline-block;
      font-size: 1.4em;
      font-weight: 500;
      margin-left: 0.25em;
      margin-right: 0.25em;
    }

    &__day-names {
      display: table;
      padding: 0 1em;
      width: 100%;
    }

    &__day-name {
      display: table-cell;
      color: $color-grey-middle-1;
      font-weight: 500;
      font-size: 14px;
      width: 14.285714286%;
    }

    &__month {
      box-sizing: border-box;
      margin: 0 0.75em 0.75em;
    }

    &__week {
      display: table;
      width: 100%;
    }

    &__day {
      border-radius: 0;
      display: table-cell;
      font-size: 18px;
      padding: 3px 0;
      outline: 0;
      width: 14.285714286%;
    }

    &__day--keyboard-selected {
      background-color: transparent;
      color: $color-black;
    }

    &__day--today:not(.react-datepicker__day--selected) {
      background-color: transparent;
      color: $color-blue-dark-2;
      &:hover {
        background-color: $color-grey-light-1;
      }
    }

    &__navigation {
      outline: 0;
    }

    &__month-container {
      width: 100%;
    }
  }
}

#sentry-feedback {
  --page-margin: 0.25rem 1rem;
  --z-index: 9;
}
