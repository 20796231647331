@import '@assets/styles/variables.scss';

.travel-request-trip-list-page {
  max-width: $width-md !important;

  .table {
    margin-bottom: 25px;

    &-empty-text {
      margin: 20px auto;
      max-width: 450px;
      white-space: pre-wrap;
    }

    .hbh-table-dark {
      tr {
        th {
          padding: 16px 8px;

          > &:first-child {
            width: 40px;
          }

          &:nth-child(2) {
            width: 160px;
          }
        }

        td {
          padding: 8px;

          &:last-child {
            width: 120px !important;

            svg {
              fill: $color-white;
            }
          }
        }
      }
    }

    .ant-table-cell {
      &.ant-table-row-expand-icon-cell {
        vertical-align: middle;

        .icon-expand,
        .icon-expanded {
          cursor: pointer;
          transform: scale(0.7);
          transition: transform 0.5s;

          svg {
            fill: $color-white;
          }
        }

        .icon-expanded {
          transform: rotate(180deg);
        }
      }
    }

    .ant-table-expanded-row {
      > td {
        border-bottom-width: 4px !important;
        padding: 0 !important;
      }

      .table-trips {
        th,
        td {
          width: auto !important;

          &:first-child {
            padding-left: 50px;
          }
        }
      }
    }

    .row-disabled {
      td {
        background: lighten($color-grey-dark-4, 10%) !important;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
  }
}
